import React from 'react';
import Particles from 'react-particles-js';

const particleOptSVG = {
  polygon: {
    enable: true,
    scale: 2,
    type: 'inline',
    move: {
        radius: 25
    },
    inline: {
      arrangement: "equidistant",
    },
    url: 'chiralbun.svg'
  },
  "fps_limit": 20,
  "interactivity": {
    "events": {
        "onhover": {
            "enable": true,
            "mode": "bubble"
        }
    },
    "modes": {
        "bubble": {
            "size": 6,
            "distance": 40
        }
    }
  },
	"particles": {
	  "collisions": {
	      "enable": false
	  },
	  "number": {
	      "value": 180,
	      "density": {
	          "enable": false
	      }
	  },
	  "line_linked": {
	      "enable": true,
	      "distance": 70,
	      "opacity": 0.5
	  },
	  "move": {
	      "speed": 1
	  },
	  "opacity": {
	      "anim": {
	          "enable": true,
	          "opacity_min": 0.08,
	          "speed": 1,
	          "sync": false
	      },
	      "value": 0.5
	  }
	},
  
};
const particleOpt = {
  "particles": {
      "color": {
          "value": "#888",
      },
      "number": {
          "value": 50,
          "density": {
              "enable": true,
              "value_area": 800
          }
      },
      "size": {
          "value": 5,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
      },


      "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 3,
            "opacity_min": 0.1,
            "sync": false
          }
      },


      "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#888",
          "opacity": 0.4,
          "width": 1
      },

      "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#888"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "img/github.svg",
            "width": 100,
            "height": 100
          }
      },
      "move": {
          "enable": true,
          "speed": 6,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
      }

  },

  
  "interactivity": {
      "events": {
          "onhover": {
              "enable": true,
              "mode": "repulse"
          }
      }
  }
}

export const ParticlesOverlay = () => {
  try{
    return ( <Particles className="particle" params={ particleOptSVG } />);
  }catch(ex) {
    return ( <Particles className="particle" params={ particleOpt } />);
  }
  
}